import React from "react";
import "../../../styles/Toast.scss";
import { useAppBreakpoints } from "../../../hooks/useAppBreakpoints";

export default function GuestToastBar({
  isOpened = false,
  handleClose,
  message = "As a guest user on Favichat, your messages will be lost if your cache is cleared.",
  isOnlyMsg = false,
  btnText = "",
}) {
  const onSignIn = () => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window.open("http://favifun", "_blank").focus();
    } else {
      window.open("com.favifun://deeplink", "_blank").focus();
    }
  };
  const { isMobile } = useAppBreakpoints();
  const isIpad = !!(navigator.userAgent.match(/(iPad)/)
  || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1;

  return (
    <div
      className={`fixed left-0 bottom-0 z-50 ${
        isMobile 
          ? "w-full" 
          : isIpad 
            ? "w-[30%]" 
            : "w-[24%]"
      } px-[.5rem] py-2`}
    >
      <div
        className={`shadow-lg bg-[#99cef6] py-[6px] px-[16px] w-full flex items-center justify-between ${
          isOpened ? "block" : "hidden"
        }`}
      >
        <div className="flex-grow text-left font-bold text-[#FFFFFF] text-[12px] pr-[20px]">
          {message}
        </div>
        <div className="flex-grow text-center">
          <button className="text-blue-500 underline" onClick={onSignIn}>
            {btnText}
          </button>
        </div>
        <div className="absolute right-5 bottom-4">
          <button
            className="text-gray-400 hover:text-gray-500"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-[1rem] w-[1rem]"
              viewBox="0 0 20 20"
              fill="#FFFFFF"
            >
              <path
                fillRule="evenodd"
                d="M10.707 10l3.532-3.532a1 1 0 1 0-1.414-1.414L9.293 8.586 5.76 5.053a1 1 0 0 0-1.414 1.414L7.88 10l-3.532 3.532a1 1 0 0 0 1.414 1.414L9.293 11.414l3.532 3.532a1 1 0 0 0 1.414-1.414L10.707 10z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
