import "./App.css";
import { Url } from "./axios/authAPI";
import React,{ useEffect, useState } from "react";
import { Login } from "./pages/Login/Login";
import Setting from "./pages/SettingPage/Setting";
import Interest from "./pages/LandingPage/Interest";
import InboxPage from "./pages/InboxPage/InboxPage";
import { useDispatch, useSelector } from "react-redux";
import PurchasePop from "./components/PopUp/PurchasePop";
import Help from "./pages/SettingPage/sidebarPages/Help";
import Hearts from "./pages/SettingPage/sidebarPages/Hearts";
import DiscoverPage from "./pages/DiscoverPage/DiscoverPage";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import InboxChatbox from "./components/InboxPage/InboxChatbox";
import Profile from "./pages/SettingPage/sidebarPages/Profile";
import CashOut from "./pages/SettingPage/sidebarPages/CashOut";
import FollowersPage from "./pages/FollowersPage/FollowersPage";
import GuestUserPage from "./pages/GuestUserPage/GuestUserPage";
import UserModal from "./components/UserDetailsModal/UserModal";
import FollowingPage from "./pages/FollowingPage/FollowingPage";
import { Verification } from "./pages/Verification/Verification";
import SendAllModal from "./components/SendAllModal/SendAllModal";
import SupportUrl from "./pages/SettingPage/sidebarPages/Support";
import InvalidUrlPage from "./pages/InvalidUrlPage/InvalidUrlPage";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import InboxDummyPage from "./components/InboxPage/InboxDummyPage";
import LinkSocial from "./pages/SettingPage/sidebarPages/LinkSocial";
import Performance from "./pages/SettingPage/sidebarPages/Performance";
import { Navigate, Route, Routes, useNavigate,useLocation } from "react-router-dom";
import BlockedUsers from "./pages/SettingPage/sidebarPages/BlockedUsers";
import MobileHeart from "./pages/MobilePurchaseHeart/MobilePurchaseHeart";
import PrivacyProfile from "./pages/SettingPage/sidebarPages/PrivacyProfile";
import TermsConditions from "./pages/SettingPage/sidebarPages/TermsConditions";
import MessagingPrivacy from "./pages/SettingPage/sidebarPages/MessagingPrivacy";
import InterestsSetting from "./pages/SettingPage/sidebarPages/InterestsSetting";
import { TermsAndConditions } from "./pages/TermsAndCondition/TermsAndConditions";
import InterestUserPage from "./components/DiscoverInterestPage/InterestUserPage";
import DiscoverInterestPage from "./pages/DiscoverInterestPage/DiscoverInterestPage";
import { stopUnsubscribeBlockUser, GetBlockUsers, UpdateOnlineUser, DiscoverModalProfileState, GetConversations, GetNotifications, GetUserData, stopUnsubscribeConversations, stopUnsubscribeNotifications, stopUnsubscribeUserData, stopUnsubscribeCurrentChatUser, stopUnsubscribeBockUserToMe, stopUnsubscribeMessages, stopUnsubscribeTyping } from "./redux/actions/index";
import MaintenancePage from "./pages/LandingPage/MaintenancePage";

function App() {

  const dispatch = useDispatch();
const navigate = useNavigate(); 
  const [authorized, setAuthorized] = useState(false);
  const [purchasePopup, setPurchasePopup] = useState(false);
  const [networkStatus, setNetworkStatus] = useState("online");

  const userData = useSelector((state) => state.user);
  const isLogIn = useSelector((state) => state.isLogIn);
  const isGuest = useSelector((state) => state.isGuest);
  const showProfile = useSelector((state) => state.showProfile);
  const purchasePopToggle = useSelector((state) => state.purchasePopToggle);
  const sendAllPopToggle = useSelector((state) => state.sendAllPopToggle);


  /* Update screen size */
  useEffect(() => {
    function resize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    window.addEventListener("resize", resize);
    window.addEventListener("load", resize);

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("load", resize);
    };
  }, []);

  /* Change offline Firebase */
  // useEffect(() => {
  //   const handleTabClose = () => {
  //     dispatch(UpdateOnlineUser({
  //       online: false
  //     }))
  //   };

  //   window.addEventListener("beforeunload", handleTabClose);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleTabClose);
  //   };
  // }, []);

  /* Change online status */
  window.addEventListener("online", () => {
    setNetworkStatus("online");
  });

  /* Change offline status */
  window.addEventListener("offline", () => {
    setNetworkStatus("offline");
  });

  /* Change offline Firebase */
  // window.onbeforeunload = function () {
  //   navigator.sendBeacon(`${Url}/offline`, userData.userId);
  // };

  /* Close profile modal (discovery) */
  const handleCloseProfileLoggedIn = () => {
    dispatch(DiscoverModalProfileState(false));
  };

  useEffect(() => {
    if (isLogIn) {
      dispatch(GetConversations({
        userId: userData.userId,
        userName: userData.userName,
        new: true,
      }));

      dispatch(GetNotifications({
        userId: userData.userId,
        username: userData.userName
      }))

      dispatch(GetUserData());

      dispatch(GetBlockUsers(userData.userId))
    } else {
      dispatch(stopUnsubscribeConversations());
      dispatch(stopUnsubscribeNotifications());
      dispatch(stopUnsubscribeUserData());
      dispatch(stopUnsubscribeCurrentChatUser());
      dispatch(stopUnsubscribeBockUserToMe());
      dispatch(stopUnsubscribeMessages());
      dispatch(stopUnsubscribeTyping());
      dispatch(stopUnsubscribeBlockUser());
    }
  }, [isLogIn])
  
   const useQuery = () => {
     const { search } = useLocation();
     return React.useMemo(() => new URLSearchParams(search), [search]);
   };
  const query = useQuery();
  
  useEffect(() => {
    const quertyType = query.get("type") == "heart" || query.get("type") == "cashout" ? true : false;
    if (!quertyType) {
      if (window.location.pathname.split("/").includes("go") || window.location.pathname.split("/").includes("login") || window.location.pathname.split("/").includes("sign-up")) {
        navigate(window.location.pathname)
      } else if (!isLogIn) {
        navigate("/")
      } else if (isLogIn && (userData?.interests?.length == 0 || userData?.interests?.length == null) && !(isGuest)) {
        navigate("/interest")
      }
    }
  },[isLogIn,userData])

  return (
    <div className="App">
      {networkStatus !== "online" 
        ? (<InvalidUrlPage
            isNavRequired={false}
            isLogIn={false}
            errorCode={"NO NETWORK"}
            error={"NETWORK ERROR"}
            errorMessage={"PLEASE CHECK YOUR CONNECTION"}
            isBackButton={false}
          />)
        : (<Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/privacypolicy" element={<TermsAndConditions />} />
            <Route path="/reset-password" element={<ForgotPassword />} />
            <Route path="/sign-up" element={<Verification />} />

            <Route path="/interest" element={<Interest />} />
            <Route path="/discover" element={<DiscoverPage />} />
            <Route path="/following" element={<FollowingPage />} />
            <Route path="/followers" element={<FollowersPage />} />

            <Route exact path="/u" element={<DiscoverInterestPage />}>
              <Route path=":link/:title" element={<InterestUserPage />} />
            </Route>

            <Route path="/inbox" element={<InboxPage />}>
              <Route path=":userId" element={<InboxChatbox />} />
              <Route path="start" element={<InboxDummyPage />} />
            </Route>

            <Route path="/settings" element={<Setting />}>
              <Route path="profile" element={<Profile />} />
              <Route path="link-social-media" element={<LinkSocial />} />
              <Route path="performance" element={<Performance />} />
              <Route path="cash-out" element={<CashOut />} />
              <Route path="hearts" element={<Hearts />} />
              <Route path="interests" element={<InterestsSetting />} />
              <Route path="messaging-privacy" element={<MessagingPrivacy />} />
              <Route path="blocked-users" element={<BlockedUsers />} />
              <Route path="terms-and-conditions" element={<TermsConditions />}/>
              <Route path="privacy-policy" element={<PrivacyProfile />} />
              <Route path="help">
                <Route index element={<Help />} />
                <Route path="terms-and-conditions" element={<TermsConditions />}/>
                <Route path="privacy-policy" element={<PrivacyProfile />} />
                <Route path="support" element={<SupportUrl />} />
              </Route>
            </Route>

            <Route path="/go/:userName" element={<GuestUserPage setAuthorized={setAuthorized} />} />
            <Route path="/mobile/:uuid" element={<MobileHeart />} />

            <Route path="/invalid-url" element={<InvalidUrlPage isNavRequired={Boolean(userData)} isLogIn={Boolean(isLogIn)} />}/>
            <Route path="*" element={<Navigate to="/invalid-url" replace />} />

          </Routes>)
      }

      {purchasePopToggle === true && (<PurchasePop setPurchasePopup={setPurchasePopup} />)}

      {sendAllPopToggle === true && (<SendAllModal />)}

      {showProfile.show === true && (<UserModal info={showProfile} close={handleCloseProfileLoggedIn} />)}

    </div>
  );
}

export default App;
