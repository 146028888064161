import "../../../styles/CashOut.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios, Url } from "../../../axios/authAPI";
import { useDispatch, useSelector } from "react-redux";
import paypalLogo from "../../../assets/icons/paypal.png";
import paypalTag from "../../../assets/icons/paypal_PNG10.png";
import { useAppBreakpoints } from "../../../hooks/useAppBreakpoints";
import GuestToastBar from "../../../components/GuestComponents/Toast/Toast";
import FavichatLoader from "../../../components/SkeletonLoader/FavichatLoader";
import CashoutTransactionCard from "../../../components/setting/CashoutTransactionCard";
import { UpdateMobileAppCashoutState, ResetNotUserState, UpdateIsGuestState, UpdateOnlineUser, UpdateSettingSidebarToggleState } from "../../../redux/actions/index";

const CashOut = () => {

  let api = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { isMobile } = useAppBreakpoints();

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const mobileAppCashout = useSelector((state) => state.mobileAppCashout);

  const [paypalEmail, setPaypalEmail] = useState("");
  const [paypalPopUp, setPaypalPopUp] = useState(false);
  const [showErrMessage, setShowErrMessage] = useState("");
  const [loginUserDetail, getLoginUserDetail] = useState();
  const [transactionData, setTransactionData] = useState([]);
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [paypalLinkedError, setPaypalLinkedError] = useState(null);
  const [paypalBackendStatus, setPaypalBackendStatus] = useState(null);
  const [paypalLinkedSuccess, setPaypalLinkedSuccess] = useState(null);
  const [transactionAccordion, setTransactionAccordion] = useState(false);
  const [showMobileToast, setShowMobileToast] = useState(mobileAppCashout);
  const [limit, setLimit] = useState(10);
  const [paginationHistory, setPaginationHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [limitPage, setLimitPage] = useState(null);
  const isIpad = !!(navigator.userAgent.match(/(iPad)/)
  || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1;

  const handleTransactionAPI = async (action) => {
    let lastRec = '';
    if (action === 'next' && transactionData.length > 0) {
      lastRec = transactionData[transactionData.length - 1].id;
      setPaginationHistory([...paginationHistory, lastRec]);
      setCurrentPage(currentPage + 1);
  } else if (action === 'prev' && currentPage > 0) {
      setCurrentPage(currentPage - 1);
      if (currentPage - 1 === 0) {
          lastRec = '';
          setPaginationHistory([]);
      } else {
          lastRec = paginationHistory[paginationHistory.length - 2];
          setPaginationHistory(paginationHistory.slice(0, -1));
      }
  }

    await api
    .get(`${Url}/transactions/cashout/list?limit=${limit}&userId=${userData.userId}&lastRec=${lastRec}`)
    .then((res) => {
        setTransactionData(res.data.data.value);
        setLimitPage(res.data.data.lastRec);
    });
};

  useEffect(() => {
    showMobileToast == "mobileAppCashout"
      ? setIsDialogboxOpen(true)
      : setIsDialogboxOpen(false);

    setShowErrMessage("loading");

    const getuserData = async () => {
      await api
        .get(
          `${Url}/users/${userData.userId}?loggedInUserId=${userData.userId}`
        )
        .then((res) => {
          getLoginUserDetail(res.data.data);
        })
        .catch((error) => {
          setShowErrMessage("Server network issues, Please try again later.");
        });
    };


    const paypalLinkorUnlinked = async () => {
      await api
        .get(`${Url}/transactions/cashout/info?userId=${userData.userId}`)
        .then((res) => {
          setPaypalBackendStatus(res?.data.success);
          setShowErrMessage("");
        })
        .catch((error) => {
          setShowErrMessage("");
          console.log(error);
        });
    };
    getuserData();
    handleTransactionAPI();
    paypalLinkorUnlinked();
  }, [paypalBackendStatus, paypalLinkedSuccess]);

  const unLinkPaypalAccountGet = async () => {
    await api
      .get(`${Url}/transactions/cashout/unlink?userId=${userData.userId}`)
      .then((res) => {
        if (res?.data.success == true) {
          setPaypalBackendStatus(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const paypalDataFn = async (e) => {

    await api
      .get(
        `${Url}/transactions/integratePaypal/link?email=${paypalEmail}&userId=${userData.userId}`
      )
      .then((res) => {
        setPaypalLinkedSuccess(res?.data?.success);
        setPaypalEmail("");
        setTimeout(() => {
          setPaypalPopUp(false);
          setPaypalEmail("");
          setPaypalLinkedSuccess(false);
          setPaypalLinkedError(false);
        }, 3000);
      })
      .catch((error) => {
        setPaypalLinkedError(true);
        setPaypalEmail("");
        setTimeout(() => {
          setPaypalPopUp(false);
          setPaypalEmail("");
          setPaypalLinkedSuccess(false);
          setPaypalLinkedError(false);
        }, 3000);
      });
  };

  const handleCloseDialog = () => {
    dispatch(UpdateMobileAppCashoutState(null));
    setIsDialogboxOpen(false);
  };

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  return (
    <>
      {!(isGuest == true) ? (
        <div className="cashOut overflow-y-auto" >
          {showErrMessage === "loading" ? (
            <FavichatLoader />
          ) : showErrMessage === "" ? (
            <div className="cashOut overflow-y-scroll">
              <div className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]"
                  onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
                    <div className="sm:hidden pt-[8px]">
                      <button type="submit" className="mr-[8px]">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                      </button>
                    </div>
                  <p className="text-[#494949] font-boboto font-bold title-profile">Cash out</p>
                </div>
              <div className="w-4/5 md:w-2/5 min-h-100 py-2 px-6 bg-white border border-gray-300 rounded-lg mx-auto overflow-hidden">
                <div className="relative  justify-center items-center h-full">
                  <h4 className="text-3xl text-center text-gray-700 opacity-88 p-2">
                    {loginUserDetail === undefined
                      ? "-.-"
                      : `$${Math.floor(loginUserDetail?.cashOut / 100).toFixed(
                          2
                        )}`}
                  </h4>
                  <div className="flex justify-center items-center p-2 md:p-0 md:pb-2">
                    <img className="h-4 mr-3" src={paypalLogo} alt="" />
                    <h6 title={loginUserDetail?.name} className="text-sm shrink font-normal truncate">
                      {loginUserDetail === undefined
                        ? "-.-"
                        : loginUserDetail?.name }
                    </h6>
                    {
                      loginUserDetail === undefined? null :
                      (
                        <>
                          <span className="mx-2 text-center"> -&nbsp;</span>
                          <div className="text-[#58a1f5] text-center flex-nowrap whitespace-nowrap">
                            {paypalBackendStatus ? "Connected" : "not-connected"}
                          </div>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
              {paypalBackendStatus ? (
                <div className="px-8 py-4">
                  <div className="">
                    <button
                      className="group cursor-pointer bg-[#58a1f5] border border-[#58a1f5] rounded-full mx-auto text-white leading-6 font-semibold text-xs px-4 py-2 flex items-center justify-between hover:bg-white transition-all duration-200"
                      onClick={unLinkPaypalAccountGet}
                    >
                      <img
                        src={paypalLogo}
                        alt=""
                        className="w-8 h-8"
                      />
                      <p className="font-normal text-xs text-white ml-3 whitespace-nowrap opacity-88 group-hover:text-[#58a1f5] transition-all duration-200">
                          Unlink account
                      </p>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="px-8 py-4">
                  <div className="">
                    <button
                      className="group cursor-pointer bg-[#58a1f5] border border-[#58a1f5] rounded-full mx-auto text-white leading-6 font-semibold text-xs px-4 py-2 flex items-center justify-between hover:bg-white transition-all duration-200"
                      onClick={(e) => setPaypalPopUp(true)}
                    >
                      <img
                        src={paypalLogo}
                        alt=""
                        className="w-8 h-8"
                      />
                      <p className="font-normal text-xs text-white ml-3 whitespace-nowrap opacity-88 group-hover:text-[#58a1f5] transition-all duration-200">
                        Link account
                      </p>
                    </button>
                  </div>
                </div>
              )}
              {paypalPopUp && (
                <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black bg-opacity-25 z-50">
                  <div className="relative w-auto bg-white rounded-3xl flex flex-col items-center justify-center -30 gap-10">
                    <button type="submit" className="absolute top-0 left-0 m-3 p-1  hover:bg-gray-300 focus:outline-none focus:bg-gray-300" onClick={(e) => setPaypalPopUp(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                    </button>
                    <div className="w-full h-50 mx-auto">
                      <div className="flex items-center justify-center px-16 pt-4 pb-2">
                        <img className="w-22 h-9 px-2 py-1" src={paypalTag} alt="" />
                      </div>
                      <h1 className="text-center text-xl">Connect with PayPal</h1>
                      <p className="text-center text-xs px-4 py-1">
                        With a PayPal account, you're eligible for FaviChat
                        cashout process.
                      </p>
                      <head>
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1.0, user-scalable=no"
                        ></meta>
                      </head>
                      <div className="h-24 flex items-center px-4">
                        <input
                          value={paypalEmail}
                          required
                          onChange={(e) => setPaypalEmail(e.target.value)}
                          className="w-4/5 text-xs px-2 py-2 border-2 border-gray-800"
                          placeholder="Enter registered paypal email"
                        />
                        <button
                          disabled={paypalEmail.length > 0 ? false : true}
                          type="submit"
                          className="w-1/5 text-sm px-2 py-2 bg-[#253b80] text-white"
                          onClick={paypalDataFn}
                        >
                          Submit
                        </button>
                      </div>
                      {paypalLinkedSuccess && <h2 className="text-center mb-5">Saved</h2>}
                      {paypalLinkedError && (
                        <h3 className="text-center mb-5">Server Error, please try again later</h3>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="w-fit mx-auto">
                <div className="gap-1 text-sm w-90 items-start px-12 md:px-2 md:pt-4 md:pb-2 justify-start flex flex-col md:flex-row ">
                  <div className="md: pr-4">
                    <p>
                      Amount:
                      <span className="font-extrabold">
                        {" "}
                        {loginUserDetail === undefined
                          ? "-.-"
                          : `$${(loginUserDetail?.cashOut / 100).toFixed(2)}`}
                      </span>
                    </p>
                  </div>
                  <div className="md: pr-4">
                    <p>
                      Fees:
                      <span className="font-extrabold">
                        {loginUserDetail === undefined
                          ? "-.-"
                          : `$${(
                              (20 / 100) *
                              (loginUserDetail?.cashOut / 100)
                            ).toFixed(2)}`}
                      </span>
                    </p>
                  </div>
                  <div className="md: pr-4">
                    <p>
                      Net payment:{" "}
                      <span className="font-extrabold">
                        {loginUserDetail === undefined
                          ? "-.-"
                          : `$${(
                              loginUserDetail?.cashOut / 100 -
                              (20 / 100) * (loginUserDetail?.cashOut / 100)
                            ).toFixed(2)}`}

                      </span>
                    </p>
                  </div>
                </div>

                  <p className="text-gray-700 text-sm w-90 opacity-90 text-left px-12 py-4 mx-auto md:px-2">
                    Creators are paid 80% of total balance automatically on the
                  first of every month. A minimum net
                    payment of $21 is required for cash out.<br />
                   {(!isMobile || !isIpad) && <br />}
                    To access your earnings, please ensure your PayPal account is linked to our platform. If you don't have one, create a PayPal account now and connect it to receive your funds promptly.
                </p>
              </div>

              <div>
                <p className="text-blue-400 font-medium text-center no-underline w-fit mx-auto py-2">
                  <Link
                    to="/settings/terms-and-conditions"
                    style={{ color: "#6eb5ed" }}
                  >
                    Learn more
                  </Link>
                </p>
              </div>

              <div className="mt-2">
                <div className="flex items-center justify-center text-[#58a1f5] py-2 border-t border-b border-gray-300 relative overflow-y-auto">
                  <p
                    className="text-base cursor-pointer"
                    onClick={(e) =>
                      setTransactionAccordion(!transactionAccordion)
                    }
                  >
                    Previous transactions details
                  </p>
                  {transactionAccordion ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>

                  ) : (

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>

                  )}
                </div>
                <div className="md:mb-8">
                  {
                  transactionAccordion && transactionData.length> 0 &&
                    transactionData?.map((data) => (
                      <CashoutTransactionCard
                        key={data.id}
                        date={new Date(parseInt(data.createdAt)).toDateString()}
                        amount={data.amount}
                        status={data.status}
                        transactionID={data.transactionReferenceId}
                        paymentMethod={data.paymentMode}
                      />
                    ))}
                    {transactionAccordion ? (
                      <div className="flex justify-center py-4 space-x-4">
                        <button
                          onClick={() => handleTransactionAPI('prev')}
                          disabled={currentPage === 0}
                          >
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            strokeWidth="1.5" 
                            stroke={currentPage === 0 ? "gray" : "currentColor"}  
                            className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                          </svg>
                        </button>
                        <button 
                          onClick={() => handleTransactionAPI('next')}
                          disabled={transactionData.length === 0 || limitPage === "NO MORE ITEMS"}> 
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            strokeWidth="1.5"  
                            stroke={transactionData.length === 0 || limitPage === "NO MORE ITEMS" ? "gray" : "currentColor"} 
                            className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                          </svg>
                        </button>
                      </div>) : (" ")
                  }
                  {transactionData.length == 0 && (
                    <p className="mt-5 text-sm text-center p-4">No previous transactions details</p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div id="cashOut_errorMessage">
              <p>{showErrMessage}</p>
            </div>
          )}

          <GuestToastBar
            isOpened={isDialogboxOpen}
            handleClose={handleCloseDialog}
            isOnlyMsg={false}
            btnText={" go back to the app"}
            message={"Continue on web browser or"}
          />

        </div>
      ) : (
        <div className="profile">
          <div className="flex items-center py-[40px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]" onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
            <div className="sm:hidden pt-[8px]">
              <button type="submit" className="mr-[8px] px-6">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                </svg>
              </button>
            </div>
            <div className="px-[10px]">
              <p className="text-[#494949] font-boboto title-profile-guest">
                This feature is not available for guest user.
                <button
                  type="submit"
                  size="small"
                  className="text-[#494949] font-boboto btn-profile-guest"
                  onClick={() => {
                      dispatch(
                        UpdateOnlineUser({
                          online: false,
                        })
                      ).then(() => {
                        guestUserLogout();
                      });
                    }}
                >
                  Create an account or Sign in
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CashOut;
