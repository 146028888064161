
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/firestore";
import { defaultEndpoint, defaultScriptUrlPattern } from "@fingerprintjs/fingerprintjs-pro-react";
import * as AWS from "aws-sdk";

const { host } = window.location;

let firebaseConfig = {
  apiKey: true,
  authDomain: true,
  projectId: true,
  storageBucket: true,
  messagingSenderId: true,
  appId: true,
  measurementId: true,
};

if (host.includes("dev") || host.includes("localhost")) {
  firebaseConfig = {
    apiKey: "AIzaSyCztM0jVzG42W-ysLB24Zfqh5BXSGdWASs",
    authDomain: "favichat--dev.firebaseapp.com",
    projectId: "favichat--dev",
    storageBucket: "favichat--dev.appspot.com",
    messagingSenderId: "149693601850",
    appId: "1:149693601850:web:aa36e2efed94312100bd03",
    measurementId: "G-ZPS0FMVQJZ"
  };
} else if (host.includes("qa")) {
  firebaseConfig = {
    apiKey: "AIzaSyCU_AMqBTGI_z92lZT9hy9RbroTQXv5OsQ",
    authDomain: "favichat-staging.firebaseapp.com",
    projectId: "favichat-staging",
    storageBucket: "favichat-staging.appspot.com",
    messagingSenderId: "165665120510",
    appId: "1:165665120510:web:5fbe52177ea76d338785af",
    measurementId: "G-Q2EWGJLW7P"
  };
} else if (host.includes("uat")) {
  firebaseConfig = {
    apiKey: "AIzaSyCoWsANcd0zEmUcu6C6Z90316bQBhiCR44",
    authDomain: "favichat--uat.firebaseapp.com",
    projectId: "favichat--uat",
    storageBucket: "favichat--uat.appspot.com",
    messagingSenderId: "707054617952",
    appId: "1:707054617952:web:631ab32c816782f9db4a05",
    measurementId: "G-EG7L5JPL8B"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyB75QZ3oTO0L0qMwjHBsOmfV4QcgXLzEhM",
    authDomain: "favichat--prod.firebaseapp.com",
    projectId: "favichat--prod",
    storageBucket: "favichat--prod.appspot.com",
    messagingSenderId: "529216027114",
    appId: "1:529216027114:web:2bd283d5879785e34f9a93",
    measurementId: "G-8STR3Y7GDY"
  }; 
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
// export const messaging = getMessaging(app);
// export const token = getToken(app);
let messaging;
  const isIpad = !!(navigator.userAgent.match(/(iPad)/)
|| (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream || isIpad;;
let ua = navigator.userAgent || navigator.vendor;
let isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
if(!isIOS || ("standalone" in window.navigator && window.navigator.standalone)){
  messaging = getMessaging(app);
}
if (isInstagram) {
  if (/iPad|iPhone|iPod/.test(ua)) {
          //window.location.href = 'googlechrome://example.com';
    console.log("browser is not available to send messages");
  } else{
    messaging = getMessaging(app);

  }
}

try {
  AWS.config.update({
    region: process.env.REACT_APP_API_REGION,
    credentials: new AWS.Credentials(process.env.REACT_APP_ACCESS_KEY,process.env.REACT_APP_SECRET_ACCESS_KEY)
  });
} catch (error) {
  console.log(error);
}

export const serverStamp = firebase.firestore.Timestamp.now().seconds * 1000;

export const guestUserCreds = {
  username: "parth.a123",
  password: "Abcd@12345",
};

export const fingerprintApi = {
  apiKey: "0FuKWkcoTKqVSpZf5Wew",
  endpoint: ["https://fp.favi.fun",defaultEndpoint],
  scriptUrlPattern: [
    "https://fp.favi.fun/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
    defaultScriptUrlPattern
  ]
};

export const requestForToken = (setPermission) => {
  try{
    if(messaging){
    return getToken(messaging, {
      vapidKey: `${process.env.REACT_APP_NOTIFICATION}`,
    }).then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("pushToken", currentToken);
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return false;
      }
    }).catch((err) => {
      console.log(err)
      return false;
    });
    } else {
      console.log("cant use sdk messaging");
      return false;
    }
  } catch(error){
    console.log(error);
    return false;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

export default { app };
